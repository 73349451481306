import React from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import aufsteller from "./img/aufsteller.png";
import "./App.css";
import "./style/main.css";

function AGB() {
    return (
        <div className="container mt-5 pt-5 mb-5 pb-5" id="agb">
            <h2 className="mb-4">Allgemeine Geschäftsbedingungen</h2>

            <h4>Allgemeines</h4>
            <p>
                Aufträge werden ausschließlich auf der Grundlage nachfolgender
                Bedingungen ausgeführt. Die nachstehenden allgemeinen
                Geschäftsbedingungen gelten für alle Rechtsgeschäfte der Sabocon
                GmbH, nachfolgend in Kurzform „Agentur“ genannt, mit ihren
                Vertragspartnern, nachstehend Auftraggeber genannt. Von diesen
                Geschäftsbedingungen abweichende Bedingungen des Auftraggebers
                werden von der Agentur nur nach gesonderter und schriftlicher
                Anerkennung akzeptiert. Abweichende Bedingungen des
                Auftraggebers, die die Agentur nicht ausdrücklich anerkennt,
                sind für die Agentur unverbindlich, auch wenn ihnen nicht
                ausdrücklich widersprochen wird.
                <br />
                <br />
                Alle Vereinbarungen, die zwischen der Agentur und dem
                Auftraggeber zwecks Ausführung eines Auftrages getroffen werden,
                sind in schriftlicher Form zu vereinbaren. Änderungen,
                Ergänzungen und Nebenabreden bedürfen zu ihrer Wirksamkeit der
                Schriftform.
                <br />
                <br />
                Diese Geschäftsbedingungen gelten auch für alle künftigen
                Geschäftsbeziehungen mit dem Auftraggeber, auch wenn sie nicht
                nochmals ausdrücklich vereinbart werden. 1.4.
                <br />
                <br />
                Die Agentur erbringt Dienstleistungen aus allen Bereichen der
                Beratung, Konzeption, Planung, Gestaltung und Realisierung von
                Werbe- und sonstigen Kommunikationsmaßnahmen. Die detaillierte
                Beschreibung der zu erbringenden Dienstleistungen ergeben sich
                aus Angeboten, Kostenvoranschlägen, Ausschreibungsunterlagen,
                Briefings, Projektverträgen, deren Anlagen und
                Leistungsbeschreibungen der Agentur.
            </p>
            <h4>Beauftragung</h4>
            <p>
                Grundlage für die Agenturarbeit und Vertragsbestandteil sind
                Kostenvoranschlag, Angebot oder ein Projektvertrag. Bei
                umfangreicheren Arbeiten händigt der Auftraggeber der Agentur
                ein schriftliches Briefing aus. Die Agentur ist an Ihre Angebote
                nach Abgabe 4 Wochen gebunden. Bei Leistungen durch
                Drittanbieter gelten dessen AGBs. Der Vertragstext wird nach
                Vertragsschluss von der Agentur nicht gespeichert. Die für den
                Vertragsabschluss zur Verfügung stehende Sprache ist
                ausschließlich Deutsch.
                <br />
                <br />
                Jede Änderung und/oder Ergänzung einer Beauftragung bedarf der
                Schriftform. Dadurch entstehende Mehrkosten hat der Auftraggeber
                zu tragen.
                <br />
                <br />
                Ereignisse höherer Gewalt, die die Agentur nicht zu vertreten
                hat, berechtigen die Agentur, das vom Auftraggeber beauftragte
                Projekt um die Dauer der Behinderung und einer angemessenen
                Anlaufzeit hinauszuschieben. Ein Schadensersatzanspruch vom
                Auftraggeber gegen die Agentur resultiert daraus nicht. Dies
                gilt auch dann, wenn dadurch für den Auftraggeber wichtige
                Termine und/ oder Ereignisse nicht eingehalten werden können
                und/oder nicht eintreten.
                <br />
                <br />
                Verbindliche Preis- oder Kostenangebote werden nur mit
                besonderer Kennzeichnung und schriftlich abgegeben. Preis- oder
                Kostennagebote werden netto (ohne Mehrwertsteuer) und in Euro
                erstellt. Die Preis- oder Kostenangebote erlangen
                Verbindlichkeit erst mit schriftlicher Bestätigung des Auftrages
                durch den Auftraggeber.
            </p>
            <h4>Urheber- und Nutzungsrechte</h4>
            <p>
                Der Auftraggeber erwirbt mit der vollständigen Zahlung des
                vereinbarten Honorars für die vertraglich vereinbarte Dauer und
                im vertraglich vereinbarten Umfang die Nutzungsrechte an allen
                von der Agentur im Rahmen dieses Auftrages gefertigten Arbeiten.
                Die Agentur überträgt dem Auftraggeber die für den jeweiligen
                Verwendungszweck erforderlichen Nutzungsrechte. Soweit nichts
                anderes vereinbart ist, wird nur das einfache Nutzungsrecht
                übertragen. Diese Übertragungen der Nutzungsrechte gelten,
                soweit eine Übertragung nach deutschem Recht möglich ist und
                gelten für die einfache Nutzung im Gebiet der Bundesrepublik
                Deutschland. Nutzungen die über dieses Gebiet hinausgehen,
                bedürfen einer schriftlichen Vereinbarung im Rahmen des Auftrags
                oder einer gesonderten schriftlichen Nebenabrede. Nutzungsrechte
                an Arbeiten, die bei Beendigung des Vertrages noch nicht bezahlt
                sind, verbleiben vorbehaltlich anderweitig getroffener
                Abmachungen bei der Agentur.
                <br />
                <br />
                Die im Rahmen des Auftrags erarbeiteten Leistungen sind als
                persönliche geistige Schöpfungen durch das Urheberrechtsgesetz
                geschützt. Diese Regelung gilt auch dann als vereinbart, wenn
                die nach dem Urheberrechtsgesetz erforderliche Schöpfungshöhe
                nicht erreicht ist.
                <br />
                <br /> Die Agentur darf die von ihr entwickelten Werbemittel
                angemessen und branchenüblich signieren und den erteilten
                Auftrag für Eigenwerbung publizieren. Diese Signierung und
                werbliche Verwendung kann durch eine entsprechende gesonderte
                Vereinbarung zwischen Agentur und Auftraggeber ausgeschlossen
                werden.
                <br />
                <br />
                Die Arbeiten der Agentur, inklusive Vorentwürfen, Vorschlägen,
                Reinzeichnungen und Texten sowohl für Print- bzw.
                Weberzeugnisse, dürfen ohne ausdrückliche Einwilligung der
                Agentur weder im Original noch bei der Reproduktion verändert
                werden. Jede vollständige oder teilweise Nachahmung ist
                unzulässig.
                <br />
                <br />
                Die Übertragung eingeräumter Nutzungsrechte an Dritte und/ oder
                Mehrfachnutzungen sind, soweit nicht im Erstauftrag geregelt,
                honorarpflichtig und bedürfen der Einwilligung der Agentur.
                <br />
                <br />
                Über den Umfang der Nutzung steht der Agentur ein
                Auskunftsanspruch zu. Die Agentur hat das Recht, auf den
                Vervielfältigungsstücken (Hard- und Softcopies) als Urheber
                genannt zu werden. Verletzt der Auftraggeber das Recht auf
                Namensnennung, ist er verpflichtet, der Agentur eine
                Vertragsstrafe in Höhe einer branchenüblichen Vergütung zu
                zahlen. Davon unberührt bleibt das Recht der Agentur, bei
                konkreter Schadensberechnung einen höheren Schaden geltend zu
                machen.
                <br />
                <br />
                Die Arbeiten der Agentur, inklusive Vorentwürfen, Vorschlägen,
                Reinzeichnungen und Texten sowohl für Print- bzw.
                Weberzeugnisse, dürfen ohne ausdrückliche Einwilligung der
                Agentur weder im Original noch bei der Reproduktion verändert
                werden. Jede vollständige oder teilweise Nachahmung ist
                unzulässig.
                <br />
                <br />
                Die Arbeiten der Agentur, inklusive Vorentwürfen, Vorschlägen,
                Reinzeichnungen und Texten sowohl für Print- bzw.
                Weberzeugnisse, dürfen ohne ausdrückliche Einwilligung der
                Agentur weder im Original noch bei der Reproduktion verändert
                werden. Jede vollständige oder teilweise Nachahmung ist
                unzulässig.
            </p>
        </div>
    );
}
export default AGB;

import React, { useState, useEffect } from "react";
import logo from "./img/logo_white.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import arrow from "./img/arrow_cyan.svg";
import aufsteller from "./img/aufsteller.png";
import mockup from "./img/mockup1.jpg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import "./App.css";
import "./style/main.css";

function Footer() {
    return (
        <div className="container-fluid footer pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <img className="logoFooter" src={logo} alt="" />
                        <p>&copy; 2020 Cguest </p>
                    </div>
                    <div className="col-12 col-sm-4">
                        <p>
                            info@cguest.de <br />
                            tel: 0049-6103-9849-37 <br />
                            fax: 0049-6103-9849-38
                        </p>
                    </div>
                    <div className="col-12 col-sm-4 d-flex flex-column">
                        <a href="./agb">AGB</a>
                        <a href="./datenschutz">Datenschutz</a>
                        <a href="./impressum">Impressum</a>
                        {/* <Link to="./datenschutz"></Link>
                        <Link to="./impressum"></Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;

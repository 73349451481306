import React from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor2.svg";
import phone from "./img/phone.png";
import aufsteller from "./img/aufsteller.png";
import checkin from "./img/checkin.svg";
import daten from "./img/daten.svg";
import happy from "./img/happy.svg";
import hygienisch from "./img/hygienisch.svg";
import rememberme from "./img/rememberme.svg";
import begleitperson from "./img/begleitperson.svg";
import heroIMG from "./img/heromain.png";
import "./App.css";
import "./style/main.css";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

function Description() {




  document.addEventListener("DOMContentLoaded", function (event) {
    const imgHolde =  document.querySelector("#imagestuff");
    function swapImg() {
        console.log(this)
        // imgHolde.src = {heroIMG};
    }

    imgHolde.addEventListener("click", swapImg)



});

    return (
        <div className="container mt-5 pt-5" id="description">
            <div className="row mt-10 reverse">
                <div className="col-12 col-lg-6">
                    <h2 className="mb-5">Einfach anmelden und QR-Code ausdrucken</h2>
                    <p>
                        Die Daten der Gäste werden DSVGO-konform in unserer Datenbank auf Deutschen Servern gespeichert.
                        Die Besucher/Gäste checken über den QR-Code auf Deinem Plakat oder Tischaufsteller ein und beim
                        Verlassen wieder aus. Nach Ablauf der gesetzlichen Mindestaufbewahrungsfrist werden die Daten
                        automatisch wieder gelöscht.
                    </p>
                </div>
                <div className="col-12 col-lg-6 animate">
                    <img src={monitor} alt="" />
                </div>
            </div>
            <div className="row mt-10">
                <div className="col-12 col-lg-6 animate aufstellerImg">
                    <img id="imagestuff" src={aufsteller} alt="Cguest Corona Liste Aufsteller" />
                    <div id="pagination"></div>
                </div>
                <div className="col-12 col-lg-6">
                    <h2 className="mb-5">Ihr QR Code</h2>
                    <p>
                        Sie können einfach Ihren QR Code als PDF automatisch selbst ausdrucken.<br></br>
                        Sprechen Sie uns gerne auch für spezielle Umsetzungen als Plakat, Tischaufsteller, Bierdeckel,
                        Aufkleber oder andere spezielle Lösungen an.
                    </p>
                    <h3 className="mt-5 mb-4">So funktioniert’s:</h3>
                    <ul>
                        <li>
                            Der Gast scannt den QR-code ein, trägt für sich und eventuell weitere Gruppenmitglieder die
                            Daten ein.
                        </li>
                        <li>
                            Sollte der Gast öfters kommen, kann er über unsere remember me - Funktion die Daten vom
                            letzten Besuch einfach übertragen.
                        </li>
                        <li>Beim Verlassen checked der Gast wieder über einen Scan aus.</li>
                    </ul>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col-12 col-lg-6">
                    <h2 className="mb-5">Features</h2>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex mb-4 align-items-start">
                                <img className="mr-5 featureImg" src={checkin} alt="Cguest digitale Gästeliste QR Code" />
                                <div className="featureText">
                                    <h4>Checkin / Checkout</h4>
                                    <p>
                                        Der Kunde kann selbst ein- und auschecken. Der Checkout funktioniert ganz
                                        automatisch nach dem Scan, keine weiteren Daten werden benötigt.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 d-flex mb-4 align-items-start">
                                <img className="mr-5 featureImg" src={hygienisch} alt="Gästeliste hygienisch" />
                                <div className="featureText">
                                    <h4>Umweltschonend & hygienisch</h4>
                                    <p>
                                        Wir verschwenden kein Papier, benötigen keine Stifte und Karten und deshalb kein
                                        Desinfektionsmittel!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex mb-4 align-items-start">
                                <img className="mr-5 featureImg" src={rememberme} alt="Gästeliste remember me" />
                                <div className="featureText">
                                    <h4>Remember me</h4>
                                    <p>
                                        Der Kunde kann seine Daten speichern lassen. Wenn er später wiederkommt erkennt
                                        Ihn Cguest und füllt seine Daten für Ihn aus!
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 d-flex mb-4 align-items-start">
                                <img className="mr-5 featureImg" src={begleitperson} alt="Gästeliste Begleitperson" />
                                <div className="featureText">
                                    <h4>Begleitpersonen</h4>
                                    <p>
                                        Der Kunde kann mehrere Begleitpersonen selbst eingeben. Dadurch muss nicht jedes
                                        Mitglied einer Gruppe den QR Code scannen!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex mb-4 align-items-start">
                                <img className="mr-5 featureImg" src={daten} alt="Gästeliste Datenverwaltung" />
                                <div className="featureText">
                                    <h4>Datenverwaltung</h4>
                                    <p>
                                        Die Kundendaten werden DSGVO konform in unserer Datenbank erfasst. Nach Ablauf
                                        der Frist werden sie automatisch gelöscht!
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 d-flex mb-4 align-items-start">
                                <img className="mr-5 featureImg" src={happy} alt="Gästeliste Zufriedenheit" />
                                <div className="featureText">
                                    <h4>Zufriedenheit</h4>
                                    <p>
                                        Zeit & Arbeitsersparnis für alle Beteiligten! Kunden, Veranstalter und Behörden
                                        werden dadurch deutlich entlastet!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 animate mt-5 text-right pl-5">
                    <img className="phoneImg" src={phone} alt="Corona Liste kontaktlos Handy" />
                    {/* <Player
                        id="lottie"
                        autoplay
                        loop
                        src="https://assets4.lottiefiles.com/packages/lf20_7uiupito.json"
                        style={{ height: "800px", width: "500px" }}
                    >
                        <Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
                    </Player> */}

                    {/* <lottie-player
                        src="https://assets5.lottiefiles.com/packages/lf20_riba4n2a.json"
                        background="transparent"
                        speed="1"
                        style="width: 300px; height: 300px"
                        loop
                        controls
                        autoplay
                    ></lottie-player> */}
                </div>
            </div>
        </div>
    );
}
export default Description;

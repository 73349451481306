import React from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import phone from "./img/phone1_4.svg";
import phoneneu from "./img/phone1_5.svg";
import phone2 from "./img/phonedesc.svg";
import heroIMG from "./img/heromain.png";
import kurve from "./img/kurve.svg";
import bgwhite from "./img/herobgwhite.png";
import "./App.css";
import "./style/main.css";

function Hero() {
    document.addEventListener("DOMContentLoaded", function (event) {
        let i = 0;
        setInterval(() => {
            i--;
            document.getElementById("overlay").style.backgroundPositionX = i + "px";
        }, 50);

        // function submitkontakt() {
        //     console.log("submit");
        // }

        // document
        //     .querySelector("#kontakt_submit")
        //     .addEventListener("click", function (e) {
        //         e.preventDefault();
        //         submitkontakt();
        //     });
    });
    return (
        <div className="wrapper">
        <div className="hero d-flex align-items-center">
            <div className="overlay" id="overlay"></div>
            <div className="container">
                <div className="row heroRow">
                    <div className="col-12 col-md-5 col-lg-6 pt-5 heroTop slide-in-left">
                        <h1 className="mb-4">Digitale Gästeliste für berührungslosen CheckIn</h1>
                        <p>
                            Unsere <strong> Cguest-Lösung</strong> bietet einfache Logistik durch digitale Abrufbarkeit,
                            ganz ohne App. Wir verschwenden kein Papier, benötigen keine Stifte und Karten und deshalb
                            kein Desinfektionsmittel. Der Service spart Zeit.
                        </p>
                        <div className="btnWrapper mt-5">
                            <a href="#description" className="btn btnTop mr-4 text-white">
                                Mehr Infos
                            </a>
                            {/* <div className="btn btnSecondary">Mehr Infos</div> */}
                        </div>
                    </div>
                    <div className="col-12 col-md-7 col-lg-6 slide-in-right heroBottom text-right">
                        <img src={heroIMG} key={phoneneu} alt="Cguest Phone" />
                    </div>
                </div>
            </div>
        </div>
        <div className="kurve">
                <img src={kurve} alt=""/>
            </div>
        </div>
    );
}
export default Hero;

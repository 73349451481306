import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./style/main.css";
import "./style/mobile.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Hero from "./hero";
import Menu from "./menu";
import Pricing from "./pricing";
import Description from "./description";
import Faqs from "./faqs";
import Partner from "./partner";
import Adspace from "./adspace";
import Footer from "./footer";
import Kontakt from "./kontakt";
import All from "./all";
import Impressum from "./impressum";
import Datenschutz from "./datenschutz";
import AGB from "./agb";

function App() {
    // const history = createBrowserHistory({ basename: "/landing" });

    return (
        <Router>
            <div className="app">
                <Menu />
                <Switch>
                    <Route path="/" component={All} />
                    <Route path="/impressum" component={Impressum} />
                    <Route path="/datenschutz" component={Datenschutz} />
                    <Route path="/agb" component={AGB} />
                </Switch>
                <Footer />
            </div>
        </Router>
        // <div className="app">
        //     <Menu />
        //     <Hero />
        //     <Description />
        //     <Pricing />
        //     <Faqs />
        //     <Kontakt />
        //     <Adspace />
        //     <Footer />
        // </div>
    );
}

export default App;

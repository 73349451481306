import React from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import aufsteller from "./img/aufsteller.png";
import "./App.css";
import "./style/main.css";

function Datenschutz() {
    return (
        <div className="container mt-5 pt-5 mb-5 pb-5" id="impressum">
            <h2>Datenschutzerklärung</h2>

            <h4>Zwecke der Verarbeitung</h4>
            <p>
                Daten werden erhoben, damit im Rahmen des Vertrages über die
                Nutzung die vertraglich geschuldete Leistung des Dienstes
                erbracht werden kann und die Zwecke des Vertrages erfüllt werden
                können. a) Es werden folgende Daten erhoben: Die für die
                Corona-Kontaktverfolgung vorgegebene Daten zur Erreichbarkeit:
                Vorname Nachname, Telefonnummer, Postleitzahl Wohnort, Strasse
                mit Hausnummer, ggf. Namen von Begleitpersonen und
                Tisch-Nummer/DFB-Zone
            </p>
            <p>
                b) Im Falle der Nutzung von Push-Diensten (Notifications) werden
                die Daten gespeichert, um die geschuldeten Informationen
                vertragskonform an den Kunden weiterleiten zu können. Die
                Push-Dienste können vertragsgemäß über die Funktionalitäten des
                jeweiligen Push-Dienstes (z. B. Einstellungen am Mobilgerät)
                abbestellt werden. Rechtsgrundlage ist entweder eine
                Einwilligung gemäß Art. 6 Absatz1 a) DSGVO oder aber Art. 6
                Absatz 1 b) DSGVO. Neben der Mobiltelefonnummer wird für die
                Push-Dienste ggf. eine eindeutige Geräte-ID mit der
                Mobiltelefonummer verbunden und gespeichert.
            </p>
            <p>
                c) Technisch bedingte Zugriffsberechtigungen Es werden keine
                besonderen Zugriffrsberechtigungen für die Nutzung von Cguest
                mit einem Browser benötigt.
            </p>
            <p>
                d) Erhebung von Standortdaten Es werden keine Standortdaten vom
                Gerät erhoben. Beim Eintragen in eine Anwesenheitsliste wird
                lediglich die Information (ohne Koordinaten) gespeichert, dass
                sich der Benutzer in diese Anwensenheitsliste eingetragen hat
                sowie die Inhalte der vom Benutzer ausgefüllten Felder.
            </p>
            <p>
                f) Einsatz von Cookies Cguest.de verwendet Cookies. Die
                Übermittlung von Cookies an Drittanbieter oder der Einsatz von
                Cookies für Werbezwecke erfolgt nicht. Cguest speichert den
                Eintragungszeitpunkt in eine Anwesenheitsliste in einem Cookie
                um dem Benutzer später das Abmelden von der jeweiligen
                Anwesenheitsliste zu ermöglichen. Zusätzlich kann der Benutzer
                durch Aktivieren der entsprechenden Funktion die von ihm
                gemachten Eintragungen in einem Cookie auf seinem Gerät
                speichern.
            </p>

            <h4>Empfänger oder Kategorien der Empfänger</h4>
            <p>
                Die Daten werden bei dem jeweiligen Betreiber des Dienstes bzw.
                bei dem von diesem eingeschalteten Auftragsverarbeiter
                gespeichert.
            </p>

            <h4>Übermittlung von Daten</h4>
            <p>
                a) Eine Übermittlung der Daten in ein Drittland oder an eine
                internationale Organisation finden nicht statt.
            </p>
            <p>
                b) Es findet im Rahmen der Vertragsabwicklung eine Übertragung
                der Daten an weisungsabhängige Auftragsverarbeiter statt. Diese
                werden sorgfältig ausgewählt und vertraglich verpflichtet. Eine
                Datenübermittlung erfolgt nur im Falle der ausdrücklichen
                Einwilligung hierzu oder im Falle einer dahingehenden
                gesetzlichen Regelung.
            </p>

            <h4>Speicherdauer</h4>
            <p>
                Die Daten werden für die Dauer bis zur Erfüllung des jeweiligen
                Speicherungszweckes bzw. bis zur gesetzlich zulässigen
                Höchstdauer der Speicherung gespeichert. Diese beträgt bei der
                Anwendung von Cguest 4 Wochen, einen Monat oder 5 Wochen (ja
                nach der gesetzlich vereinbarten Frist des jeweiligen
                Bundeslandes). Danach werden die Daten automatisch gelöscht.
            </p>

            <h4>Auskunftsrecht</h4>
            <p>
                Die betroffene Person hat das Recht, von dem Verantwortlichen
                eine Bestätigung darüber zu verlangen, ob sie betreffende
                personenbezogene Daten verarbeitet werden.
            </p>

            <h4>Recht zum Widerruf der Einwilligung</h4>
            <p>
                Die betroffene Person hat das Recht, eine erteilte Einwilligung
                in die Datenerhebung/-speicherung jederzeit zu widerrufen. Durch
                den Widerruf der Einwilligung wird die Rechtmäßigkeit der
                aufgrund der Einwilligung bis zum Widerruf erfolgten
                Verarbeitung nicht berührt. Die betroffene Person wird vor
                Abgabe der Einwilligung über das Recht zum Widerruf informiert.
            </p>

            <h4>Beschwerderecht bei der Aufsichtsbehörde</h4>
            <p>
                Die betroffene Person hat das Recht sich bei der
                Aufsichtsbehörde über den Umgang mit ihren personenbezogenen
                Daten oder das Verfahren der Bearbeitung und Speicherung der
                Daten zu beschweren. Zuständige Aufsichtsbehörde in Hessen ist:
            </p>
            <p>
                Der Hessische Beauftragte für Datenschutz und
                Informationsfreiheit <br />
                Gustav-Stresemann-Ring 1 <br />
                65189 Wiesbaden <br />
                Telefon: 0611-1408 0 <br />
                Telefax: 0611-1408 611 <br />
                E-Mail: poststelle@datenschutz.hessen.de <br />
                <br />
                (mailto:poststelle@datenschutz.hessen.de)
            </p>

            <h4>Automatisierte Entscheidungsfindung, Profiling</h4>
            <p>
                Profiling oder eine automatisierte Entscheidungsfindung kommen
                nicht zum Einsatz.
            </p>

            <h4>Recht auf Berichtigung</h4>
            <p>
                Die betroffene Person hat das Recht, von dem Verantwortlichen
                unverzüglich die Berichtigung sie betreffender unrichtiger
                personenbezogener Daten zu verlangen.
            </p>

            <h4>Recht auf Löschung</h4>
            <p>
                Die betroffene Person hat das Recht, von dem Verantwortlichen zu
                verlangen, dass sie betreffende personenbezogene Daten
                unverzüglich gelöscht werden, und der Verantwortliche ist
                verpflichtet, personenbezogene Daten unverzüglich zu löschen,
                wenn die Speicherung zu den Zwecken nicht mehr notwendig ist,
                die Einwilligung zur Datenspeicherung/Erhebung widerrufen worden
                ist und sonst keine Rechtsgrundlage für die Speicherung/Erhebung
                besteht, die betroffene Person der Verarbeitung widersprochen
                hat, die Daten unrechtmäßig verarbeitet wurden oder die Löschung
                einer rechtlichen Verpflichtung entspricht.
            </p>

            <h4>Recht auf Einschränkung der Verarbeitung</h4>
            <p>
                Die betroffene Person hat das Recht, von dem Verantwortlichen
                die Einschränkung der Verarbeitung zu verlangen, wenn die
                Richtigkeit der personenbezogenen Daten bestritten wird, die
                Verarbeitung unrechtmäßig ist und die betroffene Person statt
                der Löschung der Daten die Einschränkung der Nutzung verlangt,
                der Verantwortliche die personenbezogenen Daten nicht mehr
                benötigt, die betroffene Person sie jedoch zur Verfolgung von
                Rechtsansprüchen benötigt oder die betroffene Person Widerspruch
                eingelegt hat, solange noch nicht feststeht, ob die berechtigten
                Gründe des Verantwortlichen die des Betroffenen überwiegen.
            </p>
            <h4>Mitteilung bei Berichtigung oder Löschung der Daten</h4>
            <p>
                Der Verantwortliche teilt allen Empfängern, denen
                personenbezogenen Daten offengelegt wurden, jede Berichtigung
                oder Löschung der personenbezogenen Daten oder eine
                Einschränkung der Verarbeitung mit.
            </p>
            <h4>Recht auf Datenübertragbarkeit</h4>
            <p>
                Die betroffene Person hat das Recht, die sie betreffenden
                personenbezogenen Daten, die sie einem Verantwortlichen
                bereitgestellt hat, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten und in diesem Format auch
                einem anderen Verantwortlichen zu übermitteln, wenn der
                Verarbeitung der Daten eine Einwilligung zugrunde liegt und die
                Verarbeitung automatisiert erfolgt.
            </p>
        </div>
    );
}
export default Datenschutz;

import React from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import aufsteller from "./img/aufsteller.png";
import haken from "./img/haken.svg";
import "./App.css";
import "./style/main.css";

function Pricing() {
    return (
        <div className="container mt-5 pt-5 mb-5 pb-5" id="preis">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-4">
                    <div className="box d-flex flex-column align-items-center">
                        <h5>BASIC</h5>
                        <div className="feature">
                            <img src={haken} alt=""/>
                            <div>
                             1 Betriebsstätte
                             </div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            CheckIn / Checkout</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            Remember me - Funktion</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            QR Code PDF</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            Begleitpersonen - Funktion</div>
                            </div>
                        <hr />
                        <div className="price">€ 9.90 / Monat</div>
                        <div className="small">jährliche Abrechnung</div>
                        <a href="./shop?product=1" className="btn buy">
                            Paket wählen
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4 zIndex">
                    <div className="box d-flex flex-column align-items-center premium">
                        <h5>PREMIUM</h5>
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            1 Betriebsstätte</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            CheckIn / Checkout</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            Remember me - Funktion</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            QR Code PDF</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            Begleitpersonen - Funktion</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            eigenes Branding möglich</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            optionale Felder</div>
                            </div>
                        <hr />
                        <div className="price">€ 14.90 / Monat</div>
                        <div className="small">jährliche Abrechnung</div>
                        <a href="shop?product=2" className="btn">
                            Paket wählen
                        </a>
                        <div className="overlaySmall"></div>
                    </div>
                </div>
                <div className="col-12 col-md-12 col-lg-4">
                    <div className="box d-flex flex-column align-items-center">
                        <h5>INDIVIDUELL</h5>
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            Mehrere Betriebsstätten</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            Rabatt ab 5 Betriebsstätte</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            individuelles Branding von Cguest</div>
                            </div>
                        <hr />
                        <div className="feature">
                        <img src={haken} alt=""/>
                        <div>
                            individuell gestalteter QR Code</div>
                            </div>
                        <hr />
                        <div className="anfrage">auf Anfrage</div>
                        <a href="shop?product=3" className="btn buy">
                            Anfrage
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Pricing;

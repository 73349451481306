import React from "react";
import logo from "./img/logo_white.svg";
import logo2 from "./img/logo_gradient.svg";
import "./App.css";
import "./style/main.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Menu() {
    return (
        <div className="menuWrapper">
            <div className="container-fluid menuSticky d-flex align-items-center">
                <div className="container-fluid menu">
                    <div className="row d-flex align-items-center">
                        <div className="col-3 col-md-3 pl-5">
                            <a href="./">
                                <img className="logo" src={logo2} alt="" />
                            </a>
                        </div>
                        <div className="col-5 d-flex justify-content-end">
                            <div className="menuItem ml-4">
                                <a href="./#description">Überblick</a>
                            </div>
                            <div className="menuItem ml-4">
                                <a href="./#preis">Preise</a>
                            </div>
                            <div className="menuItem ml-4">
                                <a href="./#faq">FAQ</a>
                            </div>
                            <div className="menuItem ml-4">
                                <a href="./#kontakt">Kontakt</a>
                            </div>
                        </div>
                        <div className="col-4 col-xl-3 pt-1 d-flex justify-content-end">
                            <a className="menuBtn" href="./shop?product=1">
                                <div className="login shopLink">Bestellen</div>
                            </a>
                            <a className="menuBtn loginBtn" href="./login">
                                <button className="login">Login</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid menuStickyMobile d-flex align-items-center">
                <div className="container-fluid menu">
                    <div className="row d-flex align-items-center">
                        <div className="col-6 col-md-4">
                            <img className="logo" src={logo2} alt="" />
                        </div>

                        <div className="col-6 col-xl-3 pt-1 d-flex justify-content-end">
                            <a href="./shop?product=1">
                                <div className="login shopLink">Bestellen</div>
                            </a>
                            <a href="./login">
                                <button className="login loginBtn">Login</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Menu;

import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import arrow from "./img/arrow_cyan.svg";
import "./App.css";
import "./style/main.css";

function Faqs() {
    const [flipper, setflipper] = useState("");

    document.addEventListener("DOMContentLoaded", function (event) {
        Array.from(document.getElementsByClassName("question")).map((e) => {
            e.addEventListener("click", function () {
                if (e.classList.contains("active")) {
                    this.children[0].classList.remove("rotate-90-cw");
                    this.children[0].classList.add("rotate-90-ccw");
                    this.children[0].parentElement.nextSibling.classList.remove("show");
                    this.children[0].parentElement.nextSibling.classList.add("no-show");
                    this.classList.remove("active");
                } else {
                    this.children[0].classList.remove("rotate-90-ccw");
                    this.children[0].parentElement.nextSibling.classList.remove("no-show");
                    this.children[0].classList.add("rotate-90-cw");
                    this.classList.add("active");
                    this.children[0].parentElement.nextSibling.classList.add("show");
                }
            });
        });
    });

    function klapper() {
        // this.children[0].parentElement.nextSibling.classList.add("show");
    }

    return (
        <div className="container-fluid faqs mt-5 pt-5 mb-5 pb-5" id="faq">
            <div className="container ">
                <div className="row mt-10">
                    <div className="col">
                        <h2 className="mb-5">FAQS</h2>
                        <div className="faqWrapper">
                            <div className="question" onClick={klapper}>
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Wozu Cguest?
                            </div>
                            <div className="answer">
                                <p>
                                    Cguest dient als digitaler Ersatz für die gesetzlich verpflichtenden Corona Listen.
                                </p>
                                <p>
                                    Anstatt unzählige Zettel zu verwalten drucken Sie den QR Code aus und lassen Ihre
                                    Kunden den Code mit ihrem Smartphone scannen. Ihre Kunden können sich dann selbst in
                                    die Liste eintragen.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Wie funktioniert Cguest?
                            </div>
                            <div className="answer">
                                <p>
                                    Nachdem Sie Ihr Cguest Paket erworben haben bekommen Sie Ihre Zugangsdaten zu Ihrem
                                    Dashboard.
                                </p>
                                <p>
                                    Hier können Sie dann Ihren QR Code für Ihre Betriebsstätte erstellen und downloaden.
                                    Ihre Besucher können sich dann über den QR Code selbst ein- und auschecken.
                                </p>
                                <p>
                                    Sie können die Besuche direkt in Ihrem Dashboard einsehen, und falls benötigt, für
                                    das Gesundheitsamt als CSV Datei exportieren
                                </p>
                                <p>
                                    Die Einträge werden nach Ablauf der jeweiligen gesetzlich vorgeschriebenen
                                    Speicherfrist automatisch gelöscht.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Was muss ich tun?
                            </div>
                            <div className="answer">
                                <p>
                                    Einfach eines unserer drei Pakete buchen und für einen Monat jeweils nur 10,-€
                                    zahlen. Dafür gibt es pro Location einen QR-Code.
                                </p>
                                <p>
                                    Im PREMIUM Paket ist es möglich gesonderte Parameter wie zum Beispiel Zimmernummer,
                                    Tischnummer, besuchte Person, etc. einzugeben und so eine genauere Erfassung zu
                                    ermöglichen. Danach einfach den QR-Code mit Beschreibung aufhängen, aufstellen und
                                    die Besucher und Kunden auffordern, sich einzutragen.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Welche Daten werden erhoben?
                            </div>
                            <div className="answer">
                                <p>
                                    Es werden immer die gesetzlich vorausgetzten Daten erhoben (Name, Telefon und
                                    Anschrift)
                                </p>
                                <p>
                                    Im Cguest Premium Paket können Sie ebenfalls optionale Input Felder erstellen. Damit
                                    können Sie für sie relevante Daten der Kunden erfragen. Diese bleiben für den Kunden
                                    immer optional, er kann selbst entscheiden ob er sie ausfüllen möchte oder nicht.{" "}
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Brauche ich dazu eine App?
                            </div>
                            <div className="answer">
                                <p>Nein, Cguest funktioniert rein über den Browser.</p>
                                <p>
                                    Somit kann jeder unser System benutzen, ohne weitere Software installieren zu
                                    müssen.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Muss der Gast sich jedesmal neu eintragen?
                            </div>
                            <div className="answer">
                                <p>
                                    Mit Cguest ist das mehrfache Einchecken am gleichen Ort mittels remember me -
                                    Funktion erleichtert worden.
                                </p>
                                <p>
                                    Hierbei werden mittels Cookies die Daten nach einmaligem Eingeben gespeichert und
                                    wenn man sich später über Cguest wiedder einchecken will, erkennt das System einen
                                    wieder und füllt die Adressdaten automatisiert aus.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Muss sich jeder Gast selbst eintragen?
                            </div>
                            <div className="answer">
                                <p>Cguest bietet die Möglichkeit mehrere Begleitpersonen einzutragen</p>
                                <p>
                                    Ein Kunde kann nach dem Scan mehrere Leute selbst eintragen. So muss nicht jede
                                    Person einer Gruppe den QR Code neu scannen beim Checkin.
                                </p>
                                <p>
                                    Wenn ein Kunde mehrere Personen eingechecked hat und sich später auschecked werden
                                    alle Personen, die von ihm eingetragen wurden, mit ihm ausgechecked.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Warum ist Cguest so hygienisch, günstig, zeitsparend und umweltfreundlich ?
                            </div>
                            <div className="answer">
                                <p>
                                    Unsere Cguest-Lösung bietet einfache Logistik durch digitale Abrufbarkeit, ganz ohne
                                    App. Wir verschwenden kein Papier, benötigen keine Stifte und Karten und deshalb
                                    kein Desinfektionsmittel.
                                </p>
                                <p>Der Service spart Zeit</p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Warum eine digitale Liste?
                            </div>
                            <div className="answer">
                                <p>
                                    Bei CGuest werden die vom Gesundheitsamt geforderten Daten auf deutschen Servern für
                                    die vorgeschriebene Zeit gespeichert und nach Ablauf der vorgegebenen Zeit
                                    automatisch gelöscht. Sollte eine Abfrage durch das Gesundheitsamt erforderlich
                                    werden, können diese in verschiedenen digitalen Formaten zur Verfügung gestellt und
                                    so zügig und so einfach wie möglich weiter verarbeitet werden. Die
                                    Zuverfügungstellung in digitalen Formaten erleichtert den Gesundheitsämtern die
                                    Arbeit und schützt uns alle.
                                </p>
                            </div>
                            <div className="question">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Mit wem hatte der Infizierte zuletzt Kontakt?
                            </div>
                            <div className="answer">
                                <p>
                                    Wir machen mit Cguest dem Gesundheitsamt die Arbeit genau dann, wenn es darauf
                                    ankommt leichter! Von Cguest werden keine Kisten mit Zetteln dem Gesundheitsamt
                                    übergeben um die Infektionsketten in mühevoller Detektivarbeit nachvollziehen zu
                                    können. Bei CGuest werden die vom Gesundheitsamt geforderten Daten auf deutschen
                                    Servern für die vorgeschriebene Zeit gespeichert und nach Ablauf der vorgegebenen
                                    Zeit automatisch gelöscht. Sollte eine Abfrage durch das Gesundheitsamt erforderlich
                                    werden, können diese in CSV Format zur Verfügung gestellt und so zügig und so
                                    einfach wie möglich weiter verarbeitet werden. Das spart Zeit und Arbeit genau dann,
                                    wenn es darauf ankommt !
                                </p>
                            </div>

                            <div className="question last">
                                <div className="arrowDown">
                                    <img src={arrow} alt="" />
                                </div>
                                Haben Sie weitere Fragen?
                            </div>
                            <div className="answer last">
                                <p>
                                    Kontakieren Sie uns einfach unter <a href="mailto:info@cguest.de">info@cguest.de</a>
                                    !
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Faqs;

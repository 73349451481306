import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import arrow from "./img/arrow_cyan.svg";
import aufsteller from "./img/aufsteller.png";
import mockup from "./img/mockup1.jpg";

import "./App.css";
import "./style/main.css";

function Adspace() {
    return (
        <div className="container-fluid adspace pt-5 pb-5">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-8">
                        <img src="" alt="" />
                    </div>
                    <div className="col-12 col-lg-4">
                        <h2>LOREM IPSUM DOLOR SIT!</h2>
                        <p className="pt-5 mb-5">
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Nihil qui aliquam porro dolor. Id, debitis
                            similique animi veniam explicabo sequi.
                        </p>
                        <div className="btn">Jetzt kaufen</div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
export default Adspace;

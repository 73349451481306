import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import virusBG from "./img/virusBg.svg";
import monitor from "./img/monitor.svg";
import phone from "./img/phonedesc.svg";
import arrow from "./img/arrow_cyan.svg";
import "./App.css";
import "./style/main.css";

function Kontakt() {
    const [flipper, setflipper] = useState("");

    function addShake(e) {
        e.classList.add("shake-horizontal");
        setTimeout(() => {
            e.classList.remove("shake-horizontal");
        }, 800);
    }

    let theform = document.querySelector("#kontakt_form");
    let arr = [];

    function checkInputs() {
        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (document.querySelector("#name").value.length > 0) {
            arr.push(true);
        } else {
            arr.push(false);
            addShake(document.querySelector("#name"));
        }
        if (
            document.querySelector("#email").value.length > 0 &&
            document.querySelector("#email").value.match(mailformat)
        ) {
            arr.push(true);
        } else {
            arr.push(false);
            addShake(document.querySelector("#email"));
        }
        if (document.querySelector("#subject").value.length > 0) {
            arr.push(true);
        } else {
            arr.push(false);
            addShake(document.querySelector("#subject"));
        }
        if (document.querySelector("#message").value.length > 0) {
            arr.push(true);
        } else {
            arr.push(false);
            addShake(document.querySelector("#message"));
        }
    }

    document.addEventListener("DOMContentLoaded", function (event) {
        function submitkontakt() {
            checkInputs();

            if (arr.every((e) => e == true)) {
                if (document.querySelector("#checkbox").checked == true) {
                    let data = new FormData(document.getElementById("kontakt_form"));
                    document.querySelector("#kontakt_submit").style.display = "none";
                    document.querySelector(".spinner").style.display = "block";

                    fetch("api/v1/kontakt.php", {
                        method: "POST",

                        body: data,
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            document.querySelector("#kontakt_submit").style.display = "block";
                            document.querySelector(".spinner").style.display = "none";
                            document.querySelector(".succesMessage").style.display = "block";
                            arr = [];
                            Array.from(document.querySelector("#kontakt_form").querySelectorAll("input")).map(
                                (e) => (e.value = "")
                            );
                            Array.from(document.querySelector("#kontakt_form").querySelectorAll("textarea")).map(
                                (e) => (e.value = "")
                            );
                        })
                        .catch((error) => console.log(error, "error", data));
                } else {
                    addShake(document.querySelector("#kontakt_submit"));
                    document.querySelector("#labelcheckbox").style.color = "#dc3545";
                    setTimeout(() => {
                        document.querySelector("#labelcheckbox").style.color = "#303233";
                    }, 2000);
                }
            } else {
                arr = [];
            }
        }

        document.querySelector("#kontakt_submit").addEventListener("click", function (e) {
            e.preventDefault();
            submitkontakt();
        });
    });

    return (
        <div className="container-fluid kontakt mt-5 pt-5 pb-5" id="kontakt">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 mb-5">
                        <h2 className="mb-4">Schreiben Sie uns!</h2>
                        <h5>Interesse an unserem Produkt?</h5>
                        <h5>Haben Sie noch offene Fragen?</h5>
                        <div className="kontaktwrapper mt-4">
                            tel: +49 6103 98 49 37
                            <br />
                            mail: <a href="mailto#:info@cguest.de"> info@cguest.de</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-8">
                        <form id="kontakt_form" name="kontakt_form">
                            <input id="name" name="name" placeholder="Ihr Name" type="text" />
                            <input id="email" name="email" placeholder="Ihre Email" type="text" />
                            <input id="subject" name="subject" placeholder="Betreff" type="text" />
                            <textarea id="message" name="message" placeholder="Ihre Nachricht" rows="4" cols="50" />
                            <div className="btnWrapper">
                                <button id="kontakt_submit" className="kontaktBtn btn">
                                    abschicken
                                </button>
                                <div className="succesMessage">Vielen Dank für Ihre Nachricht!</div>
                            </div>
                            <div className="spinner">
                                <div class="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div className="checkWrap">
                                <input type="checkbox" id="checkbox" />
                                <label id="labelcheckbox" for="checkbox">
                                    Ich stimme zu, dass meine Angaben aus dem Formular zur Bearbeitung meiner
                                    Kontaktanfrage erhoben und verarbeitet werden.
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Kontakt;

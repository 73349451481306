import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./style/main.css";
import "./style/mobile.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Hero from "./hero";
import Menu from "./menu";
import Pricing from "./pricing";
import Description from "./description";
import Faqs from "./faqs";
import Partner from "./partner";
import Adspace from "./adspace";
import Footer from "./footer";
import Kontakt from "./kontakt";

function All() {
    return (
        <div className="all">
            <Hero />
            <Description />
            <Pricing />
            <Faqs />
            <Kontakt />
            <Adspace />
        </div>
    );
}

export default All;
